import { getCommonParamsItemLabel } from '@/utils/common'
import store from '@/store'

export const contentTableConfig = {
  title: '',
  propList: [
    {
      prop: 'cardNumber',
      label: 'card.card-no'
    },
    {
      prop: 'cardName',
      label: 'card.card-name'
    },
    {
      prop: 'cardType',
      label: 'common.app-type',
      formatter: (row) => {
        return getCommonParamsItemLabel(row, 'card_type')
      }
    },
    {
      prop: 'tenantId',
      label: 'user.tenant',
      width: 180,
      formatter: (row) => {
        return row ? store.getters.tenantDataMap[row] : ''
      }
    },
    {
      prop: 'status',
      label: 'common.app-status',
      formatter: (row) => {
        return getCommonParamsItemLabel(row, 'status')
      },
      width: 100
    },
    {
      prop: 'createdTime',
      slotName: 'formatZeroTime',
      label: 'common.app-creation-time',
      width: 160
    },

    {
      label: 'common.app-operation',
      width: '380',
      slotName: 'handler'
    }
  ],
  showIndexColumn: true
}
